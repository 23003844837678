import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
// import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import nlLocale from '@fullcalendar/core/locales/nl';

import axios from 'axios';

import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';
import '@fullcalendar/timegrid/main.css';
import '@fullcalendar/list/main.css';

import 'promise-polyfill/src/polyfill';  // IE fix


var domIsReady = (function (domIsReady) {
    var isBrowserIeOrNot = function () {
        return (!document.attachEvent || typeof document.attachEvent === "undefined" ? 'not-ie' : 'ie');
    }

    domIsReady = function (callback) {
        if (callback && typeof callback === 'function') {
            if (isBrowserIeOrNot() !== 'ie') {
                document.addEventListener("DOMContentLoaded", function () {
                    return callback();
                });
            } else {
                document.attachEvent("onreadystatechange", function () {
                    if (document.readyState === "complete") {
                        return callback();
                    }
                });
            }
        } else {
            console.error('The callback is not a function!');
        }
    }

    return domIsReady;
})(domIsReady || {});



const getEvents = (start, end, pageId) => {

    const url = pageId ? '/ajax/calendar/events' : '/website/all_events/';

    return axios.get(url, {
        params: {
            start: start,
            end: end,
            pid: pageId,
        },
        headers: { 'X-Requested-With': 'XMLHttpRequest' },
    }).then((response) => {
        // handle success
        const events = response.data;
        return events;
    }).catch((error) => {
        // handle error
        console.error(error);
    });
};

(function (document, window, domIsReady, undefined) {
    domIsReady(function () {

        $('[data-block="dm-fullcalendar"]').each(function (index, obj) {

            const pageId = $(obj).data('page-id');
            const defaultDate = $(obj).data('default-date');
            const defaultView = $(obj).data('default-view');

            const today = new Date();
            let start = new Date();
            let end = new Date();
            if (!pageId) {
                start.setDate(today.getDate());
            }
            end.setDate(today.getDate() + 365); // amount of days in future

            getEvents(start, end, pageId).then((events) => {
                let calendar = new Calendar(obj, {
                    locale: nlLocale,
                    //plugins: [dayGridPlugin, timeGridPlugin, listPlugin],
                    plugins: [dayGridPlugin, listPlugin],
                    events: events,
                    defaultView: defaultView,
                    defaultDate: defaultDate,
                    contentHeight: "auto",
                    header: {
                        left: 'prev,next,today',
                        center: 'title',
                        //right: 'listMonth,timeGridDay,timeGridWeek,dayGridMonth',
                        right: 'listMonth,dayGridMonth',
                    },
                    views: {
                        today: {
                            buttonText: 'Deze maand'
                        },
                        listMonth: {
                            // type: 'timeGrid',
                            // duration: {days: 4},
                            buttonText: 'Dag'
                        },
                        dayGridMonth: {
                            buttonText: 'Maand'
                        }
                    }
                });
                calendar.render();
            });

        });
    })

})(document, window, domIsReady);

