
// bootstrap needs jquery + popper but we could also use a CDN
//import 'jquery';
//import 'popper.js';
//import 'bootstrap';

import lazySizes from 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
lazySizes.cfg.loadMode = 0;  // no eager loading outside viewport

// $(".carousel.lazy").on("slide.bs.carousel", function(ev) {
//     // make sure the carousel item is visible
//     // console.log('sliiIde');
//     $('.carousel.lazy .carousel-item.active picture').css('display', 'block');
// });

// $(function() {
//     // auto load carousel items after some time
//     // setTimeout(() => $('.carousel.lazy picture').css('display', 'block'), 5000);
// });